import * as React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Feature from "../../components/Feature";
import Download from "../../components/Download";

import banner from "../../assets/banner.png";

import rocket from "../../assets/rocket.png";
import shield from "../../assets/shield.png";
import thunder from "../../assets/thunder.png";
import connect from "../../assets/connect.png";

import android from "../../assets/android_white.png";
import apk from "../../assets/apk_white.png";
import ios from "../../assets/ios_gray.png";

import { useJollyMaxContext } from "../../providers/JollyMaxProvider";

import { goToAddress } from "../../utils";

export default function TopContent() {
  const { status } = useJollyMaxContext();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "1285px" },
        mx: "auto",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        pt: status === "on" ? "50px" : 0,
        pb: "80px",
      }}
    >
      <Box
        className="upper-text-container"
        sx={{
          width: "658px",
          pt: "4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            mt: "34px",
            textAlign: { xs: "center", md: "initial" },
            pb: { xs: "26px", md: "initial" },
          }}
        >
          {isSmallScreen ? (
            <Typography variant="h2">
              Get Fast & Stay Secure & Everywhere
            </Typography>
          ) : (
            <Typography variant="h2">
              Get Fast & Stay Secure & Everywhere
            </Typography>
          )}
        </Box>
        <Feature
          img={rocket}
          text="Boost your gaming and watch blocked videos"
        />
        <Feature
          img={connect}
          text="Connect effortlessly to multiple servers"
        />
        <Feature
          img={shield}
          text="Stay safe online and protect your privacy"
        />
        <Feature
          img={thunder}
          text="Experience lightning-fast speeds and unparalleled security"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-around", md: "space-between" },
            alignItems: "center",
            position: { xs: "fixed", md: "relative" },
            background: { xs: "white", md: "transparent" },
            bottom: 0,
            left: 0,
            width: { xs: "100%", md: "708px" },
            height: { xs: "112px", md: "65px" },
            mt: { xs: "61px", md: "40px" },
          }}
        >
          <Download
            img={android}
            text="Android"
            handleClick={() => {
              goToAddress(
                "https://play.google.com/store/apps/details?id=com.sailfishvpn.fastly"
              );
            }}
          />
          <Download
            img={apk}
            text="Android Apk"
            handleClick={() => {
              goToAddress(
                "https://sailfishvpn.org/SailfishVPN-Release-OFFICIAL_WEBSITE-4010088-all_v0JX.apk"
              );
            }}
          />
          <Download
            img={ios}
            text="iOS"
            handleClick={() => {
              goToAddress(
                "https://apps.apple.com/us/app/sailfishvpn/id6449352031"
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "583px", md: "602px" },
          height: "410px",
          mt: { xs: "20px", md: 0 },
        }}
      >
        <img
          alt="banner"
          style={{ width: "100%", height: "100%" }}
          src={banner}
        />
      </Box>
    </Box>
  );
}
