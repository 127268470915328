import { AppBar, Toolbar, Box, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
import { useState } from "react";
import Logo from "../assets/logo.png";
import android from "../assets/android.png";
import apk from "../assets/apk.png";
import ios from "../assets/ios.png";
import JollyMax from "./JollyMax";
import { goToAddress } from "../utils";

const CustomLink = ({ sx, type, img, children, ...other }) => {
  const { pathname } = useLocation();
  const [showQRCode, setShowQRCode] = useState(false);

  // 处理鼠标悬浮事件
  const handleMouseEnter = () => {
    setShowQRCode(true);
  };

  // 处理鼠标离开事件
  const handleMouseLeave = () => {
    setShowQRCode(false);
  };
  return (
    <Link
      variant="h6"
      component={RouterLink}
      sx={{
        cursor: "pointer",
        px: "8px",
        fontWeight: 500,
        fontSize: "18px",
        height: "38px",
        color: "#000",
        lineHeight: "38px",
        position: "relative",
        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={pathname}
      underline="none"
      {...other}
    >
      {showQRCode && (type === "android" || type === "ios") && (
        <Box
          sx={{
            width: "200px",
            height: "200px",
            borderRadius: "6px",
            position: "absolute",
            top: "60px",
            left: "0",
            zIndex: 999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <QRCode
            size={170}
            bgColor="white"
            fgColor="#333"
            level="L"
            value={
              type === "android"
                ? "https://play.google.com/store/apps/details?id=com.sailfishvpn.fastly"
                : "https://apps.apple.com/us/app/sailfishvpn/id6449352031"
            }
          />
        </Box>
      )}
      <img
        style={{
          width: "28px",
          height: "28px",
          marginRight: "9px",
        }}
        alt=""
        src={img}
      />
      {children}
    </Link>
  );
};

export default function TopBar() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: { sm: "10px", xs: "2px" },
        position: "fixed",
        zIndex: "3",
        top: 0,
        background: "white",
        left: 0,
        right: 0,
      }}
    >
      <AppBar
        position="static"
        display="flex"
        sx={{
          boxShadow: "none",
          maxWidth: "1400px",
          mx: "auto",
          backgroundColor: "transparent",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            height: { xs: "120px", md: "80px" },
          }}
        >
          <Box
            sx={{
              height: { xs: "56px", md: "40px" },
              width: { xs: "283px", md: "202px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "auto",
                height: "100%",
              }}
              alt=""
              src={Logo}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "center",
              display: { xs: "none", md: "flex" },
              backgroundColor: {
                xs: "white",
                md: "transparent",
              },
              height: { xs: "112px" },
              width: { xs: "100%" },
            }}
          >
            <CustomLink
              sx={{
                borderRadius: "4px",
                minWidth: "80px",
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
              type="ios"
              img={ios}
            >
              iOS
            </CustomLink>
            <CustomLink
              sx={{
                borderRadius: "4px",
                minWidth: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              img={android}
              type="android"
              // onClick={() => {
              //   goToAddress(
              //     "https://play.google.com/store/apps/details?id=com.sailfishvpn.fastly"
              //   );
              // }}
            >
              Android
            </CustomLink>
            <CustomLink
              sx={{
                borderRadius: "4px",
                minWidth: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              img={apk}
              onClick={() => {
                goToAddress(
                  "https://sailfishvpn.org/SailfishVPN-Release-OFFICIAL_WEBSITE-4010088-all_v0JX.apk"
                );
              }}
            >
              Android Apk
            </CustomLink>
          </Box>
        </Toolbar>
      </AppBar>
      <JollyMax />
    </Box>
  );
}
